<template>
  <div class="settings-templates">
    <teleport v-if="mountedComponent" to="#settings-header">
      <TopNavigation :links="topNavLinks" />
    </teleport>

    <teleport v-if="mountedComponent" to="#header-nav">
      <CBreadcrumbItem>
        <router-link :to="{ name: 'Settings' }">Settings</router-link>
      </CBreadcrumbItem>

      <CBreadcrumbItem>
        <CSelectSearch
          v-model="selectedSetting"
          class="settings-templates__select"
          :options="settingsList"
          :search="true"
        />
      </CBreadcrumbItem>
    </teleport>

    <router-view />
  </div>
</template>

<script>
import TopNavigation from '@/components/Page/TopNavigation'
import breadcrumbsMixin from '@/views/Setting/breadcrumbsMixin'
import Gate from '@/services/Gate/Gate'
import { permissionError } from '@/services/Notify/Toasts'

export default {
  name: 'Index',
  components: { TopNavigation },
  mixins: [breadcrumbsMixin],
  data() {
    return {
      mountedComponent: false,
      canView: false,
    }
  },
  computed: {
    topNavLinks() {
      return [
        {
          title: 'Merge Fields',
          route: { name: 'SettingDocumentTemplate' },
          guard: () => {
            if (!this.canView) this.$notify(permissionError)
            return this.canView
          },
        },
        {
          title: 'Email Templates',
          route: { name: 'SettingEmailTemplates' },
          guard: () => {
            if (!this.canView) this.$notify(permissionError)
            return this.canView
          },
        },
      ]
    },
  },
  mounted() {
    this.$nextTick(async () => {
      this.mountedComponent = true
      this.canView = await Gate.can('templatesView', 'setting')
    })
  },
}
</script>

<style lang="scss" scoped>
.settings-templates {
  &__select {
    width: 162px;
  }
}
</style>
